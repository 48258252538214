import instance from "@/axios"

/** 类别列表 */
export const getVersionClassifyListApi = async (params) => {
    return instance({
        url: '/dq_admin/coinVersionClassfy/list',
        method: 'get',
        params: params
    })
}

/** 添加类别 */
export const postEditorClassifyByAddApi = async (params) => {
    return instance({
        url: '/dq_admin/coinVersionClassfy/add',
        method: 'post',
        data: params
    })
}
/** 更新类别 */
export const postEditorClassifyByUpdateApi = async (params) => {
    return instance({
        url: '/dq_admin/coinVersionClassfy/update',
        method: 'post',
        data: params
    })
}
/** 删除类别 */
export const delClassifyByIdApi = async (params) => {
    return instance({
        url: '/dq_admin/coinVersionClassfy/delById',
        method: 'get',
        params: params
    })
}