var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemBigAndSmallCoinNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold font-size-16" }, [
                  _vm._v(_vm._s(row.coinIdName))
                ]),
                _c(
                  "div",
                  { staticClass: "mt-10 font-weight-bold font-size-14" },
                  [_vm._v(_vm._s(row.coinItemIdName))]
                )
              ])
            }
          },
          {
            key: "itemClassifyVersionListSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.versionVoList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.id,
                      staticClass: "tag-class",
                      attrs: { color: "blue" }
                    },
                    [_vm._v(_vm._s(item.coinKindVersionName))]
                  )
                }),
                1
              )
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleEditorClassify(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定要删除吗?",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDeleteClassify(row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "mt-10",
                            attrs: { type: "danger", size: "small" }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("EditorClassifyPopup", {
        ref: "editorClassifyPopupEl",
        on: { success: _vm.actionSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }