var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 600,
        title: _vm.titleText,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "选择大币种" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-300 ml-5 mb-5",
                  attrs: {
                    allowClear: "",
                    showSearch: "",
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    placeholder: "请选择大币种"
                  },
                  on: {
                    search: function($event) {
                      return _vm.coinSearch($event)
                    },
                    change: function($event) {
                      return _vm.handleSelectedBigCoin($event)
                    }
                  },
                  model: {
                    value: _vm.params.coinId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "coinId", $$v)
                    },
                    expression: "params.coinId"
                  }
                },
                _vm._l(_vm.bigCoinList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.coinKindName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "选择小币种" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-300 ml-5 mb-5",
                  attrs: {
                    allowClear: "",
                    showSearch: "",
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    placeholder: "请选择小币种"
                  },
                  on: {
                    change: function($event) {
                      return _vm.handleChangeSmallCoin($event)
                    }
                  },
                  model: {
                    value: _vm.params.coinItemId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "coinItemId", $$v)
                    },
                    expression: "params.coinItemId"
                  }
                },
                _vm._l(_vm.smallCoinList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.coinKindItemName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.params.coinId && _vm.params.coinItemId
            ? _c(
                "a-form-item",
                { attrs: { label: "选择版别" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-300 ml-5 mb-5",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        mode: "multiple",
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        placeholder: "选择版别"
                      },
                      model: {
                        value: _vm.selectedVersionList,
                        callback: function($$v) {
                          _vm.selectedVersionList = $$v
                        },
                        expression: "selectedVersionList"
                      }
                    },
                    _vm._l(_vm.versionCoinList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.coinKindVersionName))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { label: "类别名称" } },
            [
              _c("a-input", {
                staticClass: "w-300 ml-10",
                attrs: {
                  allowClear: "",
                  type: "text",
                  placeholder: "请输入类别名称"
                },
                model: {
                  value: _vm.params.classfyName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "classfyName", $$v)
                  },
                  expression: "params.classfyName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "类别描述" } },
            [
              _c("a-textarea", {
                staticClass: "w-300 ml-10",
                attrs: { placeholder: "类别描述", rows: 4 },
                model: {
                  value: _vm.params.classfyDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "classfyDesc", $$v)
                  },
                  expression: "params.classfyDesc"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }