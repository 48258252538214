<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   大币种，小币种   -->
      <div slot="itemBigAndSmallCoinNameSlot" slot-scope="row">
        <div class="font-weight-bold font-size-16">{{ row.coinIdName }}</div>
        <div class="mt-10 font-weight-bold font-size-14">{{ row.coinItemIdName }}</div>
      </div>
<!--   类别分类版别   -->
      <div slot="itemClassifyVersionListSlot" slot-scope="row">
        <a-tag
            color="blue"
            class="tag-class"
            v-for="item in row.versionVoList"
            :key="item.id">{{ item.coinKindVersionName }}</a-tag>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <div>
          <a-button type="primary" size="small" @click="handleEditorClassify(row)">编辑</a-button>
        </div>
        <div>
          <a-popconfirm
              title="确定要删除吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDeleteClassify(row.id)"
          >
            <a-button class="mt-10" type="danger" size="small">删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  编辑  -->
    <EditorClassifyPopup ref="editorClassifyPopupEl" @success="actionSuccess"/>
  </div>
</template>
<script>
import {versionClassifyTableColumns} from "@/views/cmsPage/currencyMannage/versionClassifyManage/_data"
import {delClassifyByIdApi} from "@/views/cmsPage/currencyMannage/versionClassifyManage/_apis"
import EditorClassifyPopup
  from "@/views/cmsPage/currencyMannage/versionClassifyManage/_components/EditorClassifyPopup/index.vue"

export default {
  props: ['pagination', 'list'],
  components: {EditorClassifyPopup},
  data() {
    return {
      tableList: versionClassifyTableColumns,
    }
  },
  computed: {
  },
  methods: {
    /** 编辑 */
    handleEditorClassify(row) {
      this.$refs.editorClassifyPopupEl.show(row)
    },
    /** 删除 */
    async handleDeleteClassify(id) {
      const res = await delClassifyByIdApi({id: id})
      if (res.status !== '200') return
      this.$message.success(res.message || '删除成功');
      this.actionSuccess()
    },
    /** 操作成功 */
    actionSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>