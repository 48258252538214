
/** 记录列表 */
export const versionClassifyTableColumns = [
    { title: "类别名称", align: "center", dataIndex: 'classfyName', width: 150 },
    { title: "类别描述", align: "center", dataIndex: 'classfyDesc', width: 150 },
    { title: "大/小币种", align: "center", scopedSlots: {customRender: 'itemBigAndSmallCoinNameSlot'}, width: 100 },
    { title: "类别分类版别", align: "center", scopedSlots: {customRender: 'itemClassifyVersionListSlot'}, width: 600 },
    { title: "更新时间", align: "center", dataIndex: 'updateTime', width: 150 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 150 },
]

